<template>
  <div>
    <div class="option2">
      <div class="price">{{ totalPrice.toLocaleString() }}원</div>
      <div class="d-flex">
        <img :src="btnMinusImg" alt="" @click="decrement" />
        <input v-model="count" type="number" class="numberInput" min="1" max="99" />
        <img :src="btnPlusImg" alt="" @click="increment" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuantityOptions',
  props: {
    price: {
      type: Number,
      default: 0,
    },
    userCount: {
      type: Number,
      default: 1,
    },
    productId: {
      type: Number,
      default: 1,
    },
  },

  emits: ['count', 'totalPrice'],

  data() {
    return {
      isMounted: false,
      count: 1,
      btnMinusImg: require('@/assets/img/common/btn_minus_dim.svg'),
      btnPlusImg: require('@/assets/img/common/btn_plus.svg'),
      totalPrice: 0,
    };
  },

  watch: {
    //dh 주문수량 MAx 99개 아님. 스토어 쪽에서 상품을 몇개를 주느냐에 따라 달라질 것 같다.
    count: {
      immediate: true,
      handler(newVal) {
        if (this.isMounted == false) {
          return;
        }

        if (newVal >= 2 && newVal <= 98) {
          this.btnMinusImg = require('@/assets/img/common/btn_minus.svg');
          this.btnPlusImg = require('@/assets/img/common/btn_plus.svg');
        } else if (newVal <= 1) {
          this.btnMinusImg = require('@/assets/img/common/btn_minus_dim.svg');
          this.btnPlusImg = require('@/assets/img/common/btn_plus.svg');
        } else if (newVal >= 99) {
          this.btnMinusImg = require('@/assets/img/common/btn_minus.svg');
          this.btnPlusImg = require('@/assets/img/common/btn_plus_dim.svg');
        }
        if (newVal !== '' && newVal < 1) {
          this.$store.commit('alertPopup', {
            popupType: 'ErrorPopup',
            popupContent: '최소 주문 수량은 1개 입니다. 주문을 위해 최소 1개 이상 입력해 주세요.',
            popupTitle: '',
          });
          this.count = 1;
        }
        // 2025-01-07 인앱결제 수량제한 하드코딩 mjkim
        // STG : 1100 | PRD : 4494
        if (this.productId === 4494 && newVal !== '' && newVal > 5) {
          this.$store.commit('alertPopup', {
            popupType: 'ErrorPopup',
            popupContent: '주문 가능 수량을 초과하였습니다.(최대 주문 가능 수량 5개)',
            popupTitle: '',
          });
          this.count = 5;
        }
        if (newVal !== '' && newVal > 99) {
          this.$store.commit('alertPopup', {
            popupType: 'ErrorPopup',
            popupContent: '주문 가능 수량을 초과하였습니다.(최대 주문 가능 수량 99개)',
            popupTitle: '',
          });
          this.count = 99;
        }

        // 2025-01-13 인앱결제 수량제한 하드코딩 mjkim
        const countData = this.productId === 4494 && newVal !== '' && newVal > 5 ? 5 : newVal;
        this.totalPrice = this.price * countData;
        this.$emit('count', countData);

        // this.totalPrice = this.price * newVal;
        // this.$emit('count', newVal);
      },
    },
    price: {
      immediate: true,
      handler(newVal) {
        this.totalPrice = newVal * this.count;
      },
    },
    totalPrice: {
      immediate: true,
      handler(newVal) {
        this.$emit('totalPrice', newVal);
      },
    },
    userCount: {
      immediate: true,
      handler(newVal) {
        // this.count = newVal;
        this.count = newVal !== undefined ? newVal : 1;
      },
    },
  },
  created() {},

  mounted() {
    this.isMounted = true;
    this.count = this.userCount;
    this.totalPrice = this.price * this.count;
  },

  methods: {
    increment() {
      if (this.count < 99) {
        this.count++;
      } else {
        this.$store.commit('alertPopup', {
          popupType: 'ErrorPopup',
          popupContent: '주문 가능 수량을 초과하였습니다.(최대 주문 가능 수량 99개)',
          popupTitle: '',
        });
      }
    },
    decrement() {
      if (this.count > 1) {
        this.count--;
      } else {
        this.$store.commit('alertPopup', {
          popupType: 'ErrorPopup',
          popupContent: '최소 주문 수량은 1개 입니다. 주문을 위해 최소 1개 이상 입력해 주세요.',
          popupTitle: '',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.option2 {
  display: flex;
  justify-content: space-between;
}
.price {
  font-family: Pretendard;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 66px;
  letter-spacing: -2px;
  text-align: left;
  color: #222;
}

.numberInput {
  width: 136px;
  height: 64px;
  border-radius: 8px;
  border: solid 1px #dadcde;
  font-family: Pretendard;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: center;
  color: #222;
  margin: 0 8px;
}
</style>
