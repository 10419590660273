const mutations = {
  setNewAddr(state, payload) {
    state.newAddr = payload;
  },
  setDeliveryAddr(state, payload) {
    state.deliveryAddr = payload;
  },
  setTvPayUserNo(state, payload) {
    state.tvPayUserNo = payload;
  },
  setPayCardList(state, payload) {
    state.payCardList = payload;
  },
  setAddrUpdate(state, payload) {
    state.addrUpdate = payload;
  },
  setGetOrderTransCount(state, payload) {
    state.getOrderTransCount = payload;
  },
  setGetOrderTrans(state, payload) {
    state.getOrderTrans = payload;
  },
  pushGetOrderTrans(state, payload) {
    state.getOrderTrans.push(payload);
  },
  setAddrUpdateIdx(state, payload) {
    state.addrUpdateIdx = payload;
  },
  setGetOrderDetail(state, payload) {
    state.getOrderDetail = payload;
  },
  setSelectCard(state, payload) {
    state.selectCard = payload;
  },
  setSelectDiscount(state, payload) {
    state.selectDiscount = payload;
  },
  setGetOrderStore(state, payload) {
    state.getOrderStore = payload;
  },
  setCallPopup(state, payload) {
    state.callPopup = payload;
  },
  setClickCount(state, payload) {
    state.clickCount = payload;
  },
  setFastPayPopup(state, payload) {
    state.fastPayPopup = payload;
  },
  setCardCompanyList(state, payload) {
    state.cardCompanyList = payload;
  },
  cancelReq(state, payload) {
    state.cancelReq = payload;
  },
  setShopContinue(state, payload) {
    state.shopContinue = payload;
  },
  setBsPayment(state, payload) {
    state.bsPayment = payload;
  },
  smartConfirmParams(state, payload) {
    state.smartConfirmParams = payload;
  },
  orderSaveParams(state, payload) {
    state.orderSaveParams = payload;
  },
};
export default mutations;
