<template>
  <div>
    <orderSelect
      v-if="hasOptions"
      :data-list="dataList"
      @price="productPrice"
      @ongoing-checked="ongoingChecked"
      @options="options"
      @quantity="quantity"
    />
    <div v-else class="option">단일상품</div>
    <!--		<DateSelect-->
    <!--			v-if="dataList.company_id == 52"-->
    <!--			:availableDates="availableDates"-->
    <!--			@price="productPrice"-->
    <!--			@ongoingChecked="ongoingChecked"-->
    <!--			@options="options"-->
    <!--			@quantity="quantity"-->
    <!--			style="margin: 16px 40px 40px"-->
    <!--		></DateSelect>-->
    <!--		<div class="option" @click="clickEvent('calendar')">{{ this.$store.state.bbarshop.selectedDate }}</div>-->
    <div
      v-if="dataList.company_id == 52 && $store.state.bbarshop.productData.id != '1488'"
      class="options d-flex c-center r-space-between"
      @click="clickEvent('calendar', $event)"
    >
      <div class="d-flex c-center">
        {{ selectedDate }} 수령예정
        <img
          :src="require('@/assets/img/bbarshop/ic_warning.svg')"
          alt=""
          class="ic_warning"
          style="margin-left: 8px"
          @click="clickEvent('alertPopup')"
        />
      </div>
      <div class="change d-flex c-center">
        변경하기
        <img :src="require('@/assets/img/bbarshop/img_arr_right6.svg')" class="arr" alt="" style="margin: auto 32px auto 13px" />
      </div>
    </div>
    <quantityOptions
      v-if="isQuantity"
      style="margin: 40px"
      :price="price"
      :active-btn="checkedNum"
      :product-id="dataList.id"
      @count="count"
      @total-price="totalPrice"
    />
    <div v-if="hasPayCardCheck" class="btn-wrap" style="margin: 40px">
      <div ref="btnBuy" class="btn two-btn" @click="clickEvent('buyBtn')">구매하기</div>
      <div ref="btnPayment" class="btn two-btn" @click="clickEvent('fastBuyBtn')">빠른결제</div>
    </div>
    <div v-else class="btn-wrap" style="margin: 40px">
      <div ref="btnBuy" class="btn one-btn" @click="clickEvent('buyBtn')">구매하기</div>
    </div>
  </div>
</template>

<script>
import OrderSelect from '@/components/orderPayment/orderSelect';
import DateSelect from '@/components/orderPayment/DateSelect';
import QuantityOptions from '@/components/orderPayment/quantityOptions';
import { fitMixin } from '@/utils/fitMixin';
import bus from '@/utils/bus';
import $store from '@/store';

export default {
  name: 'OrderOptionSelect',
  components: {
    OrderSelect,
    QuantityOptions,
    // DateSelect,
  },

  mixins: [fitMixin],

  emits: ['closePopup'],

  data() {
    return {
      isMounted: false,
      price: 0,
      checkedNum: 0,
      activeBtn: 0,
      userOption: {},
      userCount: 1,
      userTotalPrice: 0,
      btnActivate: false,
      isQuantity: false,
      calendar: false,
      totalCost: 0,
    };
  },
  computed: {
    dataList() {
      return !this.$commons.isNull(this.$store.state.bbarshop.productData) ? this.$store.state.bbarshop.productData : [];
    },
    hasOptions() {
      return this.dataList.option.options && this.dataList.option.options.length > 0;
    },
    hasPayCardCheck() {
      this.$utils.printLog('hasPayCardCheck');
      let list = this.$store.state.orderPayment.payCardList;
      let state = list !== undefined ? list.some(user => user.isMobileUser === 'Y') : false;
      return state;
    },
    availableDates() {
      return !this.$commons.isNull(this.$store.state.bbarshop.availableDates) ? this.$store.state.bbarshop.availableDates : [];
    },
    selectedDate() {
      const today = new Date();
      let date = null;
      if (this.$store.state.bbarshop.selectedDate) {
        date = new Date(this.$store.state.bbarshop.selectedDate);
      } else {
        const tomorrow = new Date(today);
        const currentHour = today.getHours();
        if (currentHour < 17) {
          tomorrow.setDate(today.getDate() + 1);
        } else {
          tomorrow.setDate(today.getDate() + 2);
        }
        date = tomorrow;
        this.$store.commit('setSelectedDate', tomorrow);
      }
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const result = `${year}년 ${month}월 ${day}일`;

      return result;
    },
  },
  watch: {
    activeBtn(newVal) {
      if (this.isMounted == false) {
        return;
      }
      // 품절 옵션이 아닐 경우에만 버튼 acitve
      if (this.userOption === 'soldOut') {
        this.$refs.btnBuy.classList.remove('activate');
        this.$refs.btnPayment.classList.remove('activate');
        this.btnActivate = false;
      } else {
        if (this.hasPayCardCheck) {
          this.$refs.btnBuy.classList.add('activate');
          this.$refs.btnPayment.classList.add('activate');
        } else {
          this.$refs.btnBuy.classList.add('activate');
        }
        this.btnActivate = true;
      }
    },
  },
  created() {
    //빠른결제에서 왔을경우
    if (this.$store.state.orderPayment.fastPayPopup) {
      this.userSelect();
      this.closePopup();
      this.$store.commit('setFastPayPopup', false);
    }
    // if (!this.dataList.option.options || this.dataList.option.options.length === 0) {
    //   this.productPrice(this.dataList.price_discount);
    //   this.ongoingChecked('0');
    //   this.options([]);
    //   this.quantity(true);
    // }
  },
  mounted() {
    this.isMounted = true;

    if (!this.dataList.option.options || this.dataList.option.options.length === 0) {
      this.productPrice(this.dataList.price_discount);
      this.ongoingChecked('0');
      this.options([]);
      this.quantity(true);
    }
  },
  methods: {
    closePopup() {
      this.$emit('closePopup', 'fastBuyBtn');
    },
    clickEvent(sort, e) {
      switch (sort) {
        case 'buyBtn':
          if (this.btnActivate) {
            let value = {
              type: this.$store.state.bbarshop.productData.type,
              company_id: this.$store.state.bbarshop.productData.company_id,
              id: this.$store.state.bbarshop.productData.id,
            };

            this.userSelect();
            this.trade(value); //구매등록 시도
            this.$router.push('/bbarshop/orderPaymentDetail');
            this.informEvent('구매하기');
          }
          break;
        case 'fastBuyBtn':
          if (this.btnActivate) {
            let value = {
              type: this.$store.state.bbarshop.productData.type,
              company_id: this.$store.state.bbarshop.productData.company_id,
              id: this.$store.state.bbarshop.productData.id,
            };
            this.userSelect();
            this.closePopup();
            this.trade(value); //구매등록 시도
            this.informEvent('빠른결제');
          }

          break;
        case 'calendar':
          if (e.target.className && !e.target.className.includes('ic_warning')) {
            this.calendar = true;
            this.$emit('closePopup', 'calendar');
          }
          break;
        case 'alertPopup':
          this.$store.commit('alertPopup', {
            popupType: 'ErrorPopup',
            popupContent: '택배사의 사정에 따라 배송 일정이 달라 질 수 있습니다.',
          });
          break;
      }
    },
    productPrice(price) {
      this.price = price;
    },
    ongoingChecked(checkedNum) {
      this.$commons.printLog('ongoingChecked checkedNum : ', checkedNum);
      this.activeBtn = checkedNum;
      this.$commons.printLog('ongoingChecked activeBtn : ', this.activeBtn);
    },
    options(userOption) {
      // 품절 옵션이 아닐 경우에만 버튼 acitve
      if (this.userOption === 'soldOut') {
        this.$refs.btnBuy.classList.remove('activate');
        this.$refs.btnPayment.classList.remove('activate');
        this.btnActivate = false;
      }
      this.userOption = userOption;
      this.$commons.printLog('options userOption : ', userOption);
      this.$commons.printLog('options this.userOption : ', this.userOption);
    },
    count(userCount) {
      this.userCount = userCount;
    },
    totalPrice(userTotalPrice) {
      this.$utils.printLog('userTotalPrice', userTotalPrice);
      this.totalCost = userTotalPrice;
    },
    userSelect() {
      this.$utils.printLog('totalCost', this.totalCost);
      let userSelect = {
        price: this.totalCost,
        options: this.userOption,
        userCount: this.userCount,
      };
      // this.$store.state.bbarshop.userSelect = userSelect;
      this.$store.commit('setUserSelect', userSelect);
      this.$store.state.bbarshop.productData.userSelect = userSelect;
      this.$utils.printLog(this.$store.state.bbarshop.productData.userSelect);
      this.$utils.printLog(this.$store.state.bbarshop.userSelect);
    },
    quantity(is) {
      this.isQuantity = is;
    },
    informEvent(buttonName) {
      const pageInform = {
        prevPageInfo: this.$store.state.fit.pageInform.pageInfo,
        pageInfo: { buttonInfo: { button_name: buttonName } },
      };
      pageInform.prevPageInfo.buttonInfo = {
        button_name: buttonName,
      };

      this.$store.state.fit.pageInform.pageInfo = pageInform.pageInfo;
      this.$store.state.fit.pageInform.prevPageInfo = pageInform.prevPageInfo;
      this.$store.state.fit.pageInform.pageInfo.click_back = false;

      if (!this.$commons.isNull(pageInform.prevPageInfo)) {
        this.$store.dispatch('informEvent', pageInform);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-wrap {
  display: flex;
  justify-content: space-between;
}
.new-pay-btn {
  & {
    //width: 312px;
    width: 100%;
    height: 100px;
    border-radius: 8px;
    background-color: #f2f3f5;
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: -1.28px;
    text-align: center;
    color: #a8aaac;
    line-height: 100px;
  }

  &.activate {
    background-color: #2b2d2f;
    color: white;
  }
}
.one-btn {
  width: 100%;
}
.two-btn {
  width: 312px;
}
.btn {
  & {
    //width: 312px;
    height: 100px;
    border-radius: 8px;
    background-color: #f2f3f5;
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: -1.28px;
    text-align: center;
    color: #a8aaac;
    line-height: 100px;
  }

  &.activate {
    background-color: #2b2d2f;
    color: white;
  }
}
.option {
  width: 640px;
  height: 80px;
  margin: 40px 40px 0 40px;
  padding-left: 32px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #f2f3f5;
  font-size: 28px;
  line-height: 80px;
  letter-spacing: -1.12px;
  color: #2b2d2f;
}
.options {
  border: solid 1px #dadcde;
  width: 640px;
  height: 80px;
  margin: 16px 40px 0 40px;
  padding-left: 32px;
  box-sizing: border-box;
  border-radius: 8px;
  //background-color: #;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: -1.12px;
  // text-align: left;
  color: #648cff;
}
.change {
  font-size: 24px;
  line-height: 1.5;
  letter-spacing: -1.2px;
  color: #76787a;
}
</style>
