import $commons from '@/utils/commons';
import bus from '@/utils/bus';

import {
  bsProductList,
  bsProductCount,
  bsProductDetail,
  storeFilters,
  priceFilters,
  bsStoreList,
  bsStoreDetail,
  bsTagList,
  recentViewCount,
  recentViewList,
  recentViewRegister,
  recentViewDelete,
  zzimProductList,
  zzimProductRegister,
  zzimProductDelete,
  zzimStoreList,
  zzimStoreRegister,
  zzimStoreDelete,
  popularStoreList,
  popularSearchList,
  bsKeyword,
  autoComplete,
  zzimStoreLength,
  informEvent,
  viewSearchProduct,
  trades,
  orderComplete,
  productShareAttempt,
  personalCodeGet,
  personalCodeAdd,
  personalCodeUpdate,
  personalCodeDelete,
  productDetailBanner,
} from '@/apis/bbarshop';

const actions = {
  //빠숍 상품목록 조회
  async bsProductList({ commit }, payload) {
    const { data } = await bsProductList(payload);
    // commit('bsProductList', data);
    return data;
  },

  //상품목록 전체 갯수 조회
  async bsProductCount({ commit }, payload) {
    const { data } = await bsProductCount(payload);
    commit('bsProductCount', data);
  },

  //상품 상세 조회
  async bsProductDetail({ commit }, payload) {
    const { data } = await bsProductDetail(payload);
    return data;
  },

  //스토어 필터조회
  async storeFilters({ commit }, payload) {
    const { data } = await storeFilters(payload);
    return data;
  },

  //가격 필터조회
  async priceFilters({ commit }, payload) {
    const { data } = await priceFilters(payload);
    return data;
  },

  // 스토어 목록 조희
  async bsStoreList({ commit }, payload) {
    const { data } = await bsStoreList(payload);
    return data;
  },

  // 스토어 상세 조희
  async bsStoreDetail({ commit }, payload) {
    const { data } = await bsStoreDetail(payload);
    commit('setStoreDetail', data);
    return data;
  },

  //쇼핑태그 목록 조회
  async bsTagList({ commit }, payload) {
    const { data } = await bsTagList(payload);
    return data;
  },

  //최근 본 상품 갯수 조회
  async recentViewCount({ commit }) {
    const { data } = await recentViewCount();
    return data;
  },

  //최근 본 상품 목록 조회
  async recentViewList({ commit }) {
    const { data } = await recentViewList();
    commit('recentViewList', data);
    return data;
  },

  //최근 본 상품 등록
  async recentViewRegister({ commit }, payload) {
    const { data } = await recentViewRegister(payload);
    return data;
  },

  //최근 본 상품 삭제
  async recentViewDelete({ commit }, payload) {
    const { data } = await recentViewDelete(payload);
    return data;
  },

  //찜한 상품 목록 조회
  async zzimProductList({ commit }) {
    const { data } = await zzimProductList();
    commit('zzimProductList', data);
    return data;
  },

  //상품 찜 등록
  async zzimProductRegister({ commit }, payload) {
    const { data } = await zzimProductRegister(payload);
    return data;
  },

  //상품 찜 해제
  async zzimProductDelete({ commit }, payload) {
    const { data } = await zzimProductDelete(payload);
    return data;
  },

  //찜한 스토어 목록 조회
  async zzimStoreList({ commit }) {
    const { data } = await zzimStoreList();
    return data;
  },

  //찜한 스토어 목록 조회
  async zzimStoreLength({ commit }) {
    const { data } = await zzimStoreLength();
    return data;
  },

  //빠숍 스토어 찜 등록
  async zzimStoreRegister({ commit }, payload) {
    const { data } = await zzimStoreRegister(payload);
    return data;
  },

  //빠숍 스토어 찜 해제
  async zzimStoreDelete({ commit }, payload) {
    const { data } = await zzimStoreDelete(payload);
    return data;
  },

  //인기스토어 목록 조회
  async popularStoreList({ commit }) {
    const { data } = await popularStoreList();
    return data;
  },

  //인기 검색어 목록 조회
  async popularSearchList({ commit }) {
    const { data } = await popularSearchList();
    return data;
  },

  //빠숍 검색 추천 키워드 목록보기
  async bsKeyword({ commit }) {
    const { data } = await bsKeyword();
    return data;
  },

  //빠숍 자동완성
  async autoComplete({ commit }, payload) {
    const { data } = await autoComplete(payload);
    return data;
  },

  //최근 검색목록 조회(Client LocalStorage 사용)
  recentSearches({ commit }) {
    let recentSearches = [];
    if (localStorage.getItem('recentSearches')) {
      try {
        recentSearches = JSON.parse(localStorage.getItem('recentSearches'));
      } catch (e) {
        localStorage.removeItem('recentSearches');
      }
    }
    commit('recentSearches', recentSearches);
    return Promise.resolve(recentSearches);
  },

  // 상품 구매 시도 등록
  async trades({ commit }, payload) {
    const { data } = await trades(payload);
    this.commit('setProductBuyAttempt', data);
    return data;
  },

  //이벤트 발생 시 호출 공통
  async informEvent({ commit }, payload) {
    const { data } = await informEvent(payload);
    return data;
  },

  // 검색결과 상품상세 조회 정보 전달
  async viewSearchProduct({ commit }, payload) {
    const { data } = await viewSearchProduct(payload);
    return data;
  },

  //상품 구매 완료 등록
  async orderComplete({ commit }, payload) {
    const { data } = await orderComplete(payload);
    return data;
  },

  //상품 공유 시도
  async productShareAttempt({ commit }, payload) {
    const { data } = await productShareAttempt(payload);
    return data;
  },

  //개인통관부호 조회
  async personalCodeGet({ commit }, payload) {
    const { data } = await personalCodeGet(payload);
    return data;
  },

  //개인통관부호 등록
  async personalCodeAdd({ commit }, payload) {
    const { data } = await personalCodeAdd(payload);
    return data;
  },

  //개인통관부호 수정
  async personalCodeUpdate({ commit }, payload) {
    const { data } = await personalCodeUpdate(payload);
    return data;
  },

  //개인통관부호 삭제
  async personalCodeDelete({ commit }, payload) {
    const { data } = await personalCodeDelete(payload);
    return data;
  },

  async productDetailBanner({ commit }, payload) {
    const { data } = await productDetailBanner(payload);
    return data;
  },
};

export default actions;
