const getDefaultState = () => {
  return {
    bbarshopMenus: [],
    filterValue: '',
    category: {},
    store: {},
    searchStoreId: {},
    sortType: '',
    price: [],
    bsProductList: [],
    bsProductCount: {},
    zzimProductList: {},
    recentViewList: {},
    storeList: [],
    bsProductRollingList: [],
    bsProductGridList: [],
    bsProductDesignList: [],
    bsMagazineAList: [],
    bsMagazineBList: [],
    bsPassiveComponent: [],
    componentData: {},
    recentSearches: [],
    bsKeyword: [],
    bsExhibitionCategory: [],
    zzimStoreList: [],
    bsProductDetailList: [],
    componentList: [], // 페이지 별 컴포넌트 목록 정적 list
    passiveList: [], // 페이지 별 컴포넌트 목록 동적 list
    bsExhibitionParams: [],
    bsExhibitionAList: [],
    bsCategoryIndex: { idx: 0, exhibitionId: '' },
    productData: [],
    storeDetail: {},
    userSelect: {},
    productBuyAttempt: {}, //상품구매시도 응답값
    recntViewPath: false,
    productDetailIsBack: false,
    reZzimProduct: false,
    typePath: null,
    selectedAddr: '',
    changeAddr: false,
    selectedAddrIdx: 0,
    mallCode: null, // 진입 경로 코드 ( 페이지 URI, 실행 팝업: POPUP, 스토어관 : STORE, APP PUSH : PUSH, LMS : LMS 등)
    pointShopList: [], // 포인트샵 컴포넌트 목록 list
    orderPath: '',
    availableDates: [],
    unAvailableDates: [],
    selectedDate: null,
    productInform: {},
    productRollingList: [], // 추천 상품 리스트
    productRollingCompo: {},
    category1: '',
    category2: '',
    category3: '',
    personalCode: '', //개인통관고유부호
  };
};

export default getDefaultState();
