import $commons from '@/utils/commons';
import $store from '@/store';

const tvpoint = [
  {
    path: '/tvpoint/history',
    name: 'tvpointHistory',
    component: () => import('@/views/tvpoint/history'),
    async beforeEnter(to, from, next) {
      if ($store.state.tvpoint.isLogin) {
        const data = $store.dispatch('getUser', { termType: $store.getters.getTermType, deviceId: $store.getters.getUserCel });
        data.then(data => {
          if (parseInt(data.resultCode) === 0) {
            $commons.printLog('getUser success');
            $store.commit('saveGetUser', data);
          } else {
            $commons.printLog('getUser Error', data);
          }
        });
      }
      next();
    },
  },
  {
    path: '/tvpoint/banner',
    name: 'tvpointBanner',
    component: () => import('@/views/tvpoint/banner'),
    async beforeEnter(to, from, next) {
      let menuId = '';
      let operationId = '';
      if (to.params.menuId === undefined) {
        menuId = $store.state.tvpoint.subBannerInfo.menuId;
        operationId = $store.state.tvpoint.subBannerInfo[0].operationId;
      } else {
        menuId = to.params.menuId;
        operationId = to.params.operationId;
      }
      const data = await $store.dispatch('getPromotionInfo', { menuId, operationId });

      if (parseInt(data.resultCode) === 0) {
        $store.commit('setUseBannerDetail', data);

        //공유하기 숏링크 만들기
        const shareUrl = `?redirectPage=/tvpoint/banner&menuId=${menuId}&operationId=${operationId}`;
        await $commons.createShortLinkForWeb(shareUrl, data.logoImgUrl, data.promotionTitle);
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: '/tvpoint/pointUseDetail',
    name: 'tvpointUseDetail',
    component: () => import('@/views/common/toUse'),
    async beforeEnter(to, from, next) {
      await $store.dispatch('fitPointUseDetail', 1);
      next();
    },
  },
  {
    path: '/tvpoint/purchase',
    name: 'tvpointPurchase',
    component: () => import('@/views/tvpoint/purchase'),
    async beforeEnter(to, from, next) {
      next();
      // let data = $store.state.fit.pageDepth1.find(data => data.uri === '/fit/pages/pai');
      if ($store.state.fit.pageDepth2.find(data => data.uri === '/tvpoint/purchase')) {
        $store.state.fit.pageInform.prevPageInfo = !$commons.isNull($store.state.fit.pageInform.pageInfo)
          ? $store.state.fit.pageInform.pageInfo
          : null;
        $store.state.fit.pageInform.pageInfo = {};
        const pageData = $store.state.fit.pageDepth2.find(data => data.uri === '/tvpoint/purchase');
        $store.state.fit.pageInform.pageInfo.page_id = pageData.id;
        $store.state.fit.pageInform.pageInfo.title = pageData.title;
        $store.state.fit.pageInform.pageInfo.url = pageData.uri;
        $store.state.fit.pageInform.pageInfo.depth = pageData.depth;

        if ($store.state.common.clickBack) {
          $store.state.fit.pageInform.pageInfo.click_back = true;
          $store.commit('setClickBack', false);
        } else {
          $store.state.fit.pageInform.pageInfo.click_back = false;
        }

        if (!$commons.isNull($store.state.fit.pageInform.prevPageInfo)) {
          await $store.dispatch('informEvent', $store.state.fit.pageInform);
        }
      }
    },
  },
  {
    path: '/tvpoint/exchange',
    name: 'tvpointExchange',
    component: () => import('@/views/tvpoint/exchange'),
  },
  {
    path: '/tvpoint/exchange/exchangeEnd',
    name: 'tvpointExchangeExchangeEnd',
    component: () => import('@/views/tvpoint/exchange/exchangeEnd'),
  },
  {
    path: '/tvpoint/exchange/SingleTransView',
    name: 'tvpointExchangeSingleTransView',
    component: () => import('@/views/tvpoint/exchange/SingleTransView'),
  },
  {
    path: '/tvpoint/exchange/SingleTransComplete',
    name: 'tvpointExchangeSingletranscomplete',
    component: () => import('@/views/tvpoint/exchange/SingleTransComplete'),
    async beforeEnter(to, from, next) {
      if ($store.state.tvpoint.isLogin) {
        const data = $store.dispatch('getUser', { termType: $store.getters.getTermType, deviceId: $store.getters.getUserCel });
        data.then(data => {
          if (parseInt(data.resultCode) === 0) {
            $commons.printLog('getUser success');
            $commons.printLog(data);
            $store.commit('saveGetUser', data);
          } else {
            $commons.printLog('getUser Error', data);
          }
        });
      }
      next();
    },
  },
  {
    path: '/tvpoint/gift/giftEnd',
    name: 'giftEnd',
    component: () => import('@/views/tvpoint/gift/GiftEnd.vue'),
  },
  {
    path: '/tvpoint/family',
    name: 'family',
    component: () => import('@/views/tvpoint/family'),
  },
  {
    path: '/tvpoint/tmo',
    name: 'tmo',
    component: () => import('@/views/tvpoint/tmo'),
  },
  {
    path: '/tvpoint/storage',
    name: 'storage',
    component: () => import('@/views/tvpoint/storage'),
  },
  {
    path: '/tvpoint/family/get',
    name: 'addFamilyGet',
    component: () => import('@/views/tvpoint/family/get'),
  },
  {
    path: '/tvpoint/family/add',
    name: 'addFamilyAccount',
    component: () => import('@/views/tvpoint/family/add'),
  },
  {
    path: '/tvpoint/family/check',
    name: 'checkFamilyAccount',
    component: () => import('@/views/tvpoint/family/check'),
  },
  {
    path: '/tvpoint/family/check/auth',
    name: 'authFamilyAccount',
    component: () => import('@/views/tvpoint/family/check/auth'),
  },
  {
    path: '/tvpoint/family/check/checkEnd',
    name: 'familyAccountCheckEnd',
    component: () => import('@/views/tvpoint/family/check/checkEnd'),
  },
  {
    path: '/tvpoint/tmo/orderEnd',
    name: 'orderEnd',
    component: () => import('@/views/tvpoint/tmo/orderEnd'),
  },
  {
    path: '/tvpoint/pointGift',
    name: 'pointGift',
    component: () => import('@/views/tvpoint/pointGift'),
  },
  {
    path: '/tvpoint/gift/chargecoupon',
    name: 'chargeCoupon',
    component: () => import('@/views/tvpoint/gift/ChargeCoupon.vue'),
  },
  {
    path: '/tvpoint/gift/selectgift',
    name: 'selectGift',
    component: () => import('@/views/tvpoint/gift/SelectGift.vue'),
  },
  {
    path: '/tvpoint/gift/giftdetail',
    name: 'tvpoint-gift-GiftDetail',
    component: () => import('@/views/tvpoint/gift/GiftDetail.vue'),
  },
  {
    path: '/tvpoint/vod/vodAccumulateDetails',
    name: 'tvpoint-vod-GiftDetail',
    component: () => import('@/views/tvpoint/vod'),
  },
  {
    path: '/tvpoint/pay/register',
    name: 'tvpointPayRegisterIndex',
    component: () => import('@/views/tvpoint/pay/register'),
  },
  {
    path: '/tvpoint/pay/register/card',
    name: 'tvpointPayRegisterCardIndex',
    component: () => import('@/views/tvpoint/pay/register/card'),
  },
  {
    path: '/tvpoint/purchase/purchaseComplete',
    name: 'tvpointPurchaseComplete',
    component: () => import('@/views/tvpoint/purchase/purchaseComplete.vue'),
  },
  {
    path: '/tvpoint/purchase/tvpaySetPwd',
    name: 'tvpaySetPwd',
    component: () => import('@/views/tvpoint/purchase/tvpaySetPwd.vue'),
    async beforeEnter(to, from, next) {
      if ($store.state.bbarshop.orderPath === 'OrderPaymentDetail') {
        $store.commit('setOrderPath', 'OrderPaymentDetailN'); // 주문서 > 비밀번호 변경
      } else if ($store.state.bbarshop.orderPath === 'ProductDetail') {
        $store.commit('setOrderPath', 'ProductDetailN'); // 빠른구매 팝업 > 비밀번호 변경
      }
      next();
    },
  },
  {
    path: '/tvpoint/purchase/tvpayChangePwd',
    name: 'tvpayChangePwd',
    component: () => import('@/views/tvpoint/purchase/tvpayChangePwd.vue'),
  },
  {
    path: '/skpmall',
    name: 'skpmall',
    component: () => import('@/views/tvpoint/skpmall'),
  },
  {
    path: '/skpmall/result',
    name: 'skpmallResult',
    component: () => import('@/views/tvpoint/skpmall/result'),
  },
];

export default tvpoint;
