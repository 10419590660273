// jhkim
import Cache from '@/modules/ft-cache';
import Logger from '@/modules/ft-logger';
import { Service, Key } from '@/modules/ft-constants';
import FTInitSetUp from '@/modules/ft-init-setup';
import FTObject from '@/modules/ft-object';

import $commons from '@/utils/commons';
import $store from '@/store';
import { fitMixin } from '@/utils/fitMixin';
import bus from '@/utils/bus';
import $utils from '@/utils/tvPointcommon';

const common = [
  {
    path: '/bbarshop',
    name: 'bbarshop',
    // component: () => import('@/views/bbarshop/index'),
    component: () => import('@/views/bbarShopInit'),
    async beforeEnter(to, from, next) {
      let menuIndex = 0;

      // jhkim: 뒤로가기 선택시 다시 라우터 로직을 타므로, 현재 페이지로 가기 위해 설정
      const currentMenu = Cache.get(Key.CURRENT_MENU);
      if (FTObject.isNotNil(currentMenu) && currentMenu.serviceType == Service.BSHOP) {
        menuIndex = currentMenu.menuIndex;
      } else {
        // 링크이동으로 idx가 변경되었을때 espark
        menuIndex = $store.state.common.mainIdx;
      }
      //단말기에서 들어온 url의 경우, 티비포인트로 들어 올때 단말을 등록해야 함

      // jhkim: 첫 진입시에만 페이지 목록 다운
      //await $store.dispatch('fitPageList');

      // jhkim: vue3: 메뉴 저장 여부로 변경
      // const hasMenu = Cache.get(Key.HAS_MENU);
      if (from.fullPath === '/') {
        // if (hasMenu !== true) {
        // Cache.set(Key.HAS_MENU, true);
        await $store.dispatch('fitPageList');
        //fit 메뉴 탭 이름 변경
        const fitMenus = FTInitSetUp.makeMenus(Service.FIT);
        $store.commit('setFitMenus', fitMenus);

        //빠숍 메뉴 탭 이름 변경
        const bbarshopMenus = FTInitSetUp.makeMenus(Service.BSHOP);
        $store.commit('setBbarshopMenus', bbarshopMenus);

        // jhkim: 순서 점검 필ㅛ...
        Logger.debug('router: bshop: %s', JSON.stringify(fitMenus));
        Logger.debug('router: bshop: %s', JSON.stringify(bbarshopMenus));
      }

      if ($store.state.tvpoint.isLogin || !(!$store.state.tvpoint.isLogin && $store.state.common.deviceType === 2)) {
        $store.dispatch('alarmCount'); // 오른쪽 상단 알림에 띄워줄 전체 카운트를 가져옴
      }

      // jhkim: 현재 서비스, 시작 메뉴 저장
      Cache.set(Key.CURRENT_MENU, { serviceType: Service.BSHOP, menuIndex: Number(menuIndex) });
      Logger.debug('router: bshop: %d, %d, 0', Service.BSHOP, Number(menuIndex));

      next();

      if (!$store.state.bbarshop.mallCode) {
        const menu = Cache.get(Key.CURRENT_MENU);
        const pageData = $store.state.fit.pageDepth1.filter(data => data.menu_type == menu.serviceType);
        $store.commit('setMallCode', pageData[menu.menuIndex].uri);
      }
    },
  },
  {
    path: '/bbarshop/storeAll',
    name: 'StoreAll',
    component: () => import('@/views/bbarShop/storeAll'),
    async beforeEnter(to, from, next) {
      next();
      if ($store.state.fit.pageDepth2.find(data => data.uri === '/bar/pages/stores')) {
        $store.state.fit.pageInform.prevPageInfo = !$commons.isNull($store.state.fit.pageInform.pageInfo)
          ? $store.state.fit.pageInform.pageInfo
          : null;
        $store.state.fit.pageInform.pageInfo = {};
        const pageData = $store.state.fit.pageDepth2.find(data => data.uri === '/bar/pages/stores');
        $store.state.fit.pageInform.pageInfo.page_id = pageData.id;
        $store.state.fit.pageInform.pageInfo.title = pageData.title;
        $store.state.fit.pageInform.pageInfo.url = pageData.uri;
        $store.state.fit.pageInform.pageInfo.depth = pageData.depth;

        if ($store.state.common.clickBack) {
          $store.state.fit.pageInform.pageInfo.click_back = true;
          $store.commit('setClickBack', false);
        } else {
          $store.state.fit.pageInform.pageInfo.click_back = false;
        }

        if (!$commons.isNull($store.state.fit.pageInform.prevPageInfo)) {
          await $store.dispatch('informEvent', $store.state.fit.pageInform);
        }
      }
    },
  },
  {
    path: '/bbarshop/categoryDetail',
    name: 'CategoryDetail',
    component: () => import('@/views/bbarShop/categoryDetail'),
  },
  {
    path: '/bbarshop/exhibitionDetail',
    name: 'ExhibitionDetail',
    component: () => import('@/views/bbarShop/exhibitionDetail'),
    async beforeEnter(to, from, next) {
      const data = await $store.dispatch('exhibitionDetail', to.query.id);
      $store.commit('setExhibitionDetail', data);
      $store.commit('bsProductList', []); // 기획전 상세 상품 비우기

      let sourceId = '';
      let componentId = '';
      let pushType = '';
      let mallCode = '';
      let componentType = '';

      if (to.fullPath.indexOf('sourceId') > -1) {
        sourceId = to.fullPath.split('sourceId=')[1].split('&')[0];
        $store.commit('setSourceId', sourceId);
      } else if (from.fullPath.indexOf('sourceId') > -1) {
        sourceId = from.fullPath.split('sourceId=')[1].split('&')[0];
        $store.commit('setSourceId', sourceId);
      }

      if (to.fullPath.indexOf('componentId') > -1) {
        componentId = to.fullPath.split('componentId=')[1].split('&')[0];
        $store.commit('setBannerId', componentId);
      } else if (from.fullPath.indexOf('componentId') > -1) {
        componentId = from.fullPath.split('componentId=')[1].split('&')[0];
        $store.commit('setBannerId', componentId);
      }

      if (to.fullPath.indexOf('pushType') > -1) {
        pushType = to.fullPath.split('pushType=')[1].split('&')[0];
        $store.commit('setFitcolType', pushType);
      } else if (from.fullPath.indexOf('pushType') > -1) {
        pushType = from.fullPath.split('pushType=')[1].split('&')[0];
        $store.commit('setFitcolType', pushType);
      }

      if (to.fullPath.indexOf('componentType') > -1) {
        componentType = to.fullPath.split('componentType=')[1].split('&')[0];
        $store.commit('setComponentType', componentType);
      } else if (from.fullPath.indexOf('componentType') > -1) {
        componentType = from.fullPath.split('componentType=')[1].split('&')[0];
        $store.commit('setComponentType', componentType);
      }

      if (!$commons.isNull($store.state.common.fitcolType)) {
        if ($store.state.common.fitcolType === 'A') {
          mallCode = 'PUSH';
        } else if ($store.state.common.fitcolType === 'L') {
          mallCode = 'LMS';
        } else if ($store.state.common.fitcolType === 'EVENT') {
          mallCode = 'EVENT';
        } else {
          mallCode = '/fit/pages/fitcall';
        }
        $store.commit('setMallCode', mallCode);
      }

      let alarmId = '';
      if (from.fullPath.indexOf('pushId') > -1) {
        alarmId = from.fullPath.split('pushId=')[1].split('&')[0];
        $store.commit('setSalesPlatform', 'BBARS00001');
        $store.commit('setMallCode', 'PUSH');
        $store.commit('setBannerId', alarmId);
      } else if (to.fullPath.indexOf('pushId') > -1) {
        $store.commit('setSalesPlatform', 'BBARS00001');
        $store.commit('setMallCode', 'PUSH');
        $store.commit('setBannerId', alarmId);
      }

      // 기획전 상세 첫진입 상품 통신
      let idx;
      if (to.query.id == $store.state.bbarshop.bsCategoryIndex.exhibitionId) {
        idx = $store.state.bbarshop.bsCategoryIndex.idx ? $store.state.bbarshop.bsCategoryIndex.idx : 0;
      } else {
        $store.commit('bsCategoryIndex', { idx: 0, exhibitionId: to.query.id });
        idx = 0;
      }
      let productId = [];
      let dataList = data.special_selling_page;
      let products = data.special_selling_page.groups[idx].products;
      for (let i = 0; i < products.length; i++) {
        productId[i] = products[i].id;
      }
      const params = dataList['is_using_sort?']
        ? `?products=${productId}&sort_by=price_discount&sort_order=asc&per_page=40&page=1&fitcol_code=${$store.state.common.sourceId}`
        : `?products=${productId}&sort_by=products_list_order&sort_order=desc&per_page=40&page=1&fitcol_code=${$store.state.common.sourceId}`;
      await $store.dispatch('bsProductList', params).then(data => {
        $store.commit('bsProductList', data);
      });

      next();

      if (to.query.id) {
        $store.state.fit.pageInform.prevPageInfo = !$commons.isNull($store.state.fit.pageInform.pageInfo)
          ? $store.state.fit.pageInform.pageInfo
          : null;
        $store.state.fit.pageInform.pageInfo = {};
        $store.state.fit.pageInform.pageInfo.page_id = to.query.id;
        $store.state.fit.pageInform.pageInfo.title = data.special_selling_page.component_name;
        $store.state.fit.pageInform.pageInfo.url = null;
        $store.state.fit.pageInform.pageInfo.depth = 0;
        $store.state.fit.pageInform.pageInfo.pageAttribute = { type: 3, promotion_id: to.query.id };

        if ($store.state.common.clickBack) {
          $store.state.fit.pageInform.pageInfo.click_back = true;
          $store.commit('setClickBack', false);
        } else {
          $store.state.fit.pageInform.pageInfo.click_back = false;
        }

        if (!$commons.isNull(sourceId) && Object.keys($store.state.fit.pageInform.prevPageInfo).length === 0) {
          $store.state.fit.pageInform.prevPageInfo = {};
          $store.state.fit.pageInform.prevPageInfo.buttonInfo = { button_id: 1, button_name: '앱카드로 구매하기' };
          $store.state.fit.pageInform.prevPageInfo.depth = 0;
          $store.state.fit.pageInform.prevPageInfo.pageAttribute = { type: 2, event_id: to.query.eventId };
          $store.state.fit.pageInform.prevPageInfo.pageId = to.query.eventId;
          $store.state.fit.pageInform.prevPageInfo.title = '';
          $store.state.fit.pageInform.prevPageInfo.url = null;
          $commons.printLog('to.query : ', to.query);
        }

        if (!$commons.isNull($store.state.fit.pageInform.prevPageInfo)) {
          await $store.dispatch('informEvent', $store.state.fit.pageInform);
          $store.commit('setEventId', '');
        }
      }
    },
  },
  {
    path: '/bbarshop/searchresult',
    name: 'SearchResult',
    component: () => import('@/views/bbarShop/searchResult'),
    async beforeEnter(to, from, next) {
      let queryString = to.fullPath.split('searchresult')[1];
      await $store.dispatch('bsStoreList', queryString).then(res => {
        $store.commit('storeList', res);
      });
      await $store.dispatch('bsProductList', queryString).then(res => {
        $store.commit('bsProductList', res);
      });
      await $store.dispatch('bsProductCount', queryString);
      bus.$emit('end:loading');
      next();

      if ($store.state.fit.pageDepth2.find(data => data.uri === '/bar/pages/search')) {
        $store.state.fit.pageInform.prevPageInfo = !$commons.isNull($store.state.fit.pageInform.pageInfo)
          ? $store.state.fit.pageInform.pageInfo
          : null;
        $store.state.fit.pageInform.pageInfo = {};
        const pageData = $store.state.fit.pageDepth2.find(data => data.uri === '/bar/pages/search');
        $store.state.fit.pageInform.pageInfo.page_id = pageData.id;
        $store.state.fit.pageInform.pageInfo.title = pageData.title;
        $store.state.fit.pageInform.pageInfo.url = pageData.uri;
        $store.state.fit.pageInform.pageInfo.depth = pageData.depth;

        if ($store.state.common.clickBack) {
          $store.state.fit.pageInform.pageInfo.click_back = true;
          $store.commit('setClickBack', false);
        } else {
          $store.state.fit.pageInform.pageInfo.click_back = false;
        }
      }
    },
  },
  {
    path: '/bbarshop/storeHome',
    name: 'StoreHome',
    component: () => import('@/views/bbarShop/storeHome'),
    async beforeEnter(to, from, next) {
      if (from.path === '/menu') {
        $store.commit('setMallCode', null);
        $store.commit('setBannerId', null);
      } else if (from.path === '/bbarshop/zzim') {
        $store.commit('setMallCode', 'FAV_STORE');
        $store.commit('setBannerId', null);
      }

      const id = to.query.id;
      const params = `?companys=${id}&per_page=40`;
      await $store.dispatch('bsStoreDetail', id);
      $store.dispatch('bsProductList', params).then(res => {
        $store.commit('bsProductList', res);
      });
      const companyId = $store.state.fit.pageDepth1.find(data => data.company_id == id)
        ? $store.state.fit.pageDepth1.find(data => data.company_id == id).id
        : '';
      if (companyId) {
        let productRolling = [];
        const templateGroupList = await $store.dispatch('componentTemplateGroupList', companyId);
        templateGroupList.group_templates.forEach(group => {
          group.component_templates.filter(async component => {
            const params = {
              pageId: companyId,
              componentId: component.id,
              groupId: group.id,
            };

            switch (component.type) {
              case 0: {
                params.groupType = 'products';
                const data = await $store.dispatch('fitBasicApi', params);
                await $store.commit('setProductRollingList', data);
                productRolling = data.product_components;
                // 상품 롤링형
                let productRollingList = [];
                if (!$commons.isNull(productRolling)) {
                  for (let i = 0; i < productRolling.length; i++) {
                    if (productRolling[i].display_type === 0) {
                      let product = productRolling[i].products;
                      let productId = [];
                      if (product.length > 0) {
                        for (let i = 0; i < product.length; i++) {
                          productId[i] = product[i].id;
                        }
                      }
                      const params = `?products=${productId}`;
                      if (productId.length > 0) {
                        const data = await $store.dispatch('bsProductList', params);
                        productRollingList.push(data);
                      }
                    } else if (productRolling[i].display_type === 2) {
                      let data = await fitMixin.methods.autoOrganization(productRolling[i].link.type, productRolling[i].link.value);
                      productRollingList.push(data);
                    }
                  }
                  $store.commit('bsProductRollingList', productRollingList);
                }
                break;
              }
              case 14: {
                params.groupType = 'banners';
                let data = await $store.dispatch('fitBasicApi', params);
                await $store.commit('setRollingBannerList', data);
                break;
              }
            }
          });
        });
      } else if (!companyId || (companyId && $store.state.fit.productRollingList)) {
        $store.state.bbarshop.bsProductRollingList = [];
      }
      // bus.$emit('end:loading');
      next();

      if ($store.state.fit.pageDepth1.find(data => data.company_id === Number(id))) {
        $store.state.fit.pageInform.prevPageInfo = !$commons.isNull($store.state.fit.pageInform.pageInfo)
          ? $store.state.fit.pageInform.pageInfo
          : null;
        $store.state.fit.pageInform.pageInfo = {};
        const pageData = $store.state.fit.pageDepth1.find(data => data.company_id === Number(id));
        $store.state.fit.pageInform.pageInfo.page_id = pageData.id;
        $store.state.fit.pageInform.pageInfo.title = pageData.title;
        $store.state.fit.pageInform.pageInfo.url = pageData.uri;
        $store.state.fit.pageInform.pageInfo.depth = pageData.depth;
        $store.state.fit.pageInform.pageInfo.pageAttribute = { company_id: id };

        if ($store.state.common.clickBack) {
          $store.state.fit.pageInform.pageInfo.click_back = true;
          $store.commit('setClickBack', false);
        } else {
          $store.state.fit.pageInform.pageInfo.click_back = false;
        }

        if (!$commons.isNull($store.state.fit.pageInform.prevPageInfo)) {
          await $store.dispatch('informEvent', $store.state.fit.pageInform);
        }
      }
    },
  },
  {
    path: '/bbarshop/zzim',
    name: 'zzim',
    component: () => import('@/views/bbarShop/zzim'),
    props: true,
    async beforeEnter(to, from, next) {
      //테스트 후 2로 바꾸기
      if ($store.state.common.deviceType === 2 && $store.state.tvpoint.isLogin === false) {
        //웹
        $store.commit('alertPopup', { popupType: 'NeedToLoginPopup' });
        next(false);
      } else {
        //앱 진입시 로컬 비어있을떄
        if ($commons.isNull(localStorage.getItem('zzimProductList')) || localStorage.getItem('zzimProductList') === undefined) {
          localStorage.setItem('zzimProductList', JSON.stringify([]));
        }
        if ($commons.isNull(localStorage.getItem('zzimStoreList')) || localStorage.getItem('zzimStoreList') === undefined) {
          localStorage.setItem('zzimStoreList', JSON.stringify([]));
        }
        next();

        if ($store.state.fit.pageDepth2.find(data => data.uri === '/bar/pages/zzim')) {
          $store.state.fit.pageInform.prevPageInfo = !$commons.isNull($store.state.fit.pageInform.pageInfo)
            ? $store.state.fit.pageInform.pageInfo
            : null;
          $store.state.fit.pageInform.pageInfo = {};
          const pageData = $store.state.fit.pageDepth2.find(data => data.uri === '/bar/pages/zzim');
          $store.state.fit.pageInform.pageInfo.page_id = pageData.id;
          $store.state.fit.pageInform.pageInfo.title = pageData.title;
          $store.state.fit.pageInform.pageInfo.url = pageData.uri;
          $store.state.fit.pageInform.pageInfo.depth = pageData.depth;

          if ($store.state.common.clickBack) {
            $store.state.fit.pageInform.pageInfo.click_back = true;
            $store.commit('setClickBack', false);
          } else {
            $store.state.fit.pageInform.pageInfo.click_back = false;
          }
        }
      }
    },
  },
  {
    path: '/bbarshop/recntView',
    name: 'recntView',
    component: () => import('@/views/bbarShop/recntView'),
    async beforeEnter(to, from, next) {
      next();
      if ($store.state.fit.pageDepth2.find(data => data.uri === '/bar/pages/recent')) {
        $store.state.fit.pageInform.prevPageInfo = !$commons.isNull($store.state.fit.pageInform.pageInfo)
          ? $store.state.fit.pageInform.pageInfo
          : null;
        $store.state.fit.pageInform.pageInfo = {};
        const pageData = $store.state.fit.pageDepth2.find(data => data.uri === '/bar/pages/recent');
        $store.state.fit.pageInform.pageInfo.page_id = pageData.id;
        $store.state.fit.pageInform.pageInfo.title = pageData.title;
        $store.state.fit.pageInform.pageInfo.url = pageData.uri;
        $store.state.fit.pageInform.pageInfo.depth = pageData.depth;

        if ($store.state.common.clickBack) {
          $store.state.fit.pageInform.pageInfo.click_back = true;
          $store.commit('setClickBack', false);
        } else {
          $store.state.fit.pageInform.pageInfo.click_back = false;
        }
      }
    },
    // async beforeEnter(to, from, next) {
    // 	if ($store.state.tvpoint.isLogin) {
    // 		await $store.dispatch('recentViewList');
    // 	} else {
    // 		if (
    // 			$commons.isNull(localStorage.getItem('recentViewRegisters')) ||
    // 			JSON.parse(localStorage.getItem('recentViewRegisters') === undefined)
    // 		) {
    // 			localStorage.setItem('recentViewRegisters', JSON.stringify([]));
    // 		} else {
    // 			let recentViewRegisters = JSON.parse(localStorage.getItem('recentViewRegisters') || '[]');
    // 			const params = `?products=${recentViewRegisters}`;
    // 			let data = await $store.dispatch('bsProductList', params);
    // 			$store.commit('productData', data);
    // 		}
    // 	}
    // 	next();
    // },
  },
  {
    path: '/bbarshop/search',
    name: 'search',
    component: () => import('@/views/bbarShop/search'),
    async beforeEnter(to, from, next) {
      await $store.dispatch('recentSearches');
      next();
    },
  },
  {
    path: '/bbarshop/specialExhibition',
    name: 'SpecialExhibition',
    component: () => import('@/views/bbarShop/specialExhibition'),
  },
  {
    path: '/bbarshop/top100',
    name: 'Top100',
    component: () => import('@/views/bbarShop/top100'),
  },
  {
    path: '/bbarshop/productDetail',
    name: 'ProductDetail',
    component: () => import('@/views/bbarShop/productDetail'),
    async beforeEnter(to, from, next) {
      if (from.path) {
        switch (from.path) {
          case '/bbarshop/searchresult':
            if (from.fullPath.indexOf('tag') > -1) {
              if (from.fullPath.indexOf('relatedProduct') > -1) {
                $store.commit('setMallCode', 'RECOMMEND');
              } else {
                $store.commit('setMallCode', 'TAG');
              }
            } else if (from.fullPath.indexOf('name') > -1) {
              $store.commit('setMallCode', 'SEARCH');
            }
            break;
          case '/bbarshop/zzim':
            $store.commit('setMallCode', 'FAV_GOODS');
            break;
          case '/bbarshop/recntView':
            $store.commit('setMallCode', 'RECENT_GOODS');
            break;
          case '/bbarshop/storeHome':
            if ($commons.isNull($store.state.common.bannerId) && $commons.isNull($store.state.bbarshop.mallCode)) {
              $store.commit('setMallCode', 'STORE');
            }
            break;
        }
      }

      // 핏콜제휴사 페이지에서 진입 시 핏콜ID와 eventId 저장. jslim
      $commons.printLog('to.fullPath', to.fullPath);
      $commons.printLog('fullPath', from.fullPath);

      let productDetailEnter = $commons.productDetailEnter(to, from);
      if (productDetailEnter) {
        next();
      }
      // 추천상품 클릭시 상품상세>상품상세 로 이동시 router을 타지 않아 함수를 따로 뺌 240313 espark
      // let sourceId = '';
      // let componentId = '';
      // let pushType = '';
      // let mallCode = '';
      // let componentType = '';
      //
      // if (to.fullPath.indexOf('sourceId') > -1) {
      //   sourceId = to.fullPath.split('sourceId=')[1].split('&')[0];
      //   $store.commit('setSourceId', sourceId);
      // } else if (from.fullPath.indexOf('sourceId') > -1) {
      //   sourceId = from.fullPath.split('sourceId=')[1].split('&')[0];
      //   $store.commit('setSourceId', sourceId);
      // }
      //
      // if (to.fullPath.indexOf('componentId') > -1) {
      //   componentId = to.fullPath.split('componentId=')[1].split('&')[0];
      //   $store.commit('setBannerId', componentId);
      // } else if (from.fullPath.indexOf('componentId') > -1) {
      //   componentId = from.fullPath.split('componentId=')[1].split('&')[0];
      //   $store.commit('setBannerId', componentId);
      // }
      //
      // if (to.fullPath.indexOf('pushType') > -1) {
      //   pushType = to.fullPath.split('pushType=')[1].split('&')[0];
      //   $store.commit('setFitcolType', pushType);
      // } else if (from.fullPath.indexOf('pushType') > -1) {
      //   pushType = from.fullPath.split('pushType=')[1].split('&')[0];
      //   $store.commit('setFitcolType', pushType);
      // }
      //
      // if (to.fullPath.indexOf('componentType') > -1) {
      //   componentType = to.fullPath.split('componentType=')[1].split('&')[0];
      //   $store.commit('setComponentType', componentType);
      // } else if (from.fullPath.indexOf('componentType') > -1) {
      //   componentType = from.fullPath.split('componentType=')[1].split('&')[0];
      //   $store.commit('setComponentType', componentType);
      // }
      //
      // if (!$commons.isNull($store.state.common.fitcolType)) {
      //   if ($store.state.common.fitcolType === 'A') {
      //     mallCode = 'PUSH';
      //   } else if ($store.state.common.fitcolType === 'L') {
      //     mallCode = 'LMS';
      //   } else if ($store.state.common.fitcolType === 'EVENT') {
      //     mallCode = 'EVENT';
      //   } else {
      //     mallCode = '/fit/pages/fitcall';
      //   }
      //   $store.commit('setMallCode', mallCode);
      // }
      //
      // $commons.printLog('sourceId : ', $store.state.common.sourceId);
      // $commons.printLog('bannerId : ', $store.state.common.bannerId);
      // $commons.printLog('mallCode : ', $store.state.bbarshop.mallCode);
      // $commons.printLog('salesPlatform : ', $store.state.common.salesPlatform);
      // $commons.printLog('shop_type : ', to.query.shop_type);
      // $commons.printLog('uri : ', to.query.uri);
      //
      // let fitcolCode = '';
      // if (!$commons.isNull($store.state.common.sourceId)) {
      //   $commons.printLog('!$commons.isNull($store.state.common.sourceId)');
      //   fitcolCode = $store.state.common.sourceId;
      // } else if (to.query.uri === '/fit/pages/fitcall') {
      //   fitcolCode = 'FIT00FIT01';
      // }
      //
      // const id = to.query.id;
      // const params = `${id}/?fitcol_code=${fitcolCode}`;
      // // const params = !$commons.isNull($store.state.common.sourceId) ? `${id}/?fitcol_code=${$store.state.common.sourceId}` : `${id}`;
      // $store.commit('setProductId', id);
      //
      // const data = await $store.dispatch('bsProductDetail', params);
      // $store.commit('productData', data);
      //
      // if (data.company_id == 52) {
      //   const params = {
      //     appcardSubUrl: '/FIT-ORDER-RP-V1/order/getAvailableDates.mv',
      //   };
      //   $store.dispatch('fitReqRelay', params).then(data => {
      //     if (data.resultCode === '0000') {
      //       // 설 연휴 배송 불가 요청으로 클라이언트 하드코딩, mjkim
      //       const date = { date: 8, month: 1, year: 2024 }; // 2024-02-08
      //       const date2 = { date: 13, month: 1, year: 2024 }; // 2024-02-13
      //       data.resultData.dates.push(date);
      //       data.resultData.dates.push(date2);
      //       $store.commit('setUnAvailableDates', data.resultData.dates);
      //     } else {
      //       $commons.printLog('getAvailableDates error');
      //     }
      //   });
      // }
      // // const shareUrl = `?redirectPage=/bbarshop/productDetail&id=${to.query.id}`;
      // // await $commons.createShortLinkForWeb(shareUrl, data.thumbnail_image, data.name);
      // next();
      //
      // if ($store.state.tvpoint.isLogin) {
      //   $store
      //     .dispatch('getUserUsablePoint', {
      //       jsessionid: $store.state.tvpoint.jsessionId,
      //       termType: $store.state.tvpoint.termType,
      //       deviceId: $store.state.tvpoint.userCel,
      //     })
      //     .then(data => {
      //       $utils.printLog(data);
      //       if (parseInt(data.resultCode) === 0) {
      //         $utils.printLog('getUserUsablePoint success');
      //         $store.state.tvpoint.pointBalance = data.availableBalance * 1;
      //       } else {
      //         $utils.printLog('getUserUsablePoint fail');
      //       }
      //     });
      // }
      //
      // if ($commons.isNull($store.state.fit.pageDepth2[0])) {
      //   await $store.dispatch('fitPageList');
      //
      //   //fit 메뉴 탭 이름 변경
      //   const fitMenus = FTInitSetUp.makeMenus(Service.FIT);
      //   $store.commit('setFitMenus', fitMenus);
      //
      //   //빠숍 메뉴 탭 이름 변경
      //   const bbarshopMenus = FTInitSetUp.makeMenus(Service.BSHOP);
      //   $store.commit('setBbarshopMenus', bbarshopMenus);
      // }
      //
      // if (!$commons.isNull($store.state.fit.pageDepth2[0]) && $store.state.fit.pageDepth2.find(data => data.uri === '/bar/pages/product')) {
      //   // 핏콜 카드사 페이지에서 진입시 informEvent 가 안타도록 !$commons.isNull($store.state.fit.pageDepth2[0])추가 230601 espark
      //   $store.state.fit.pageInform.prevPageInfo = !$commons.isNull($store.state.fit.pageInform.pageInfo)
      //     ? $store.state.fit.pageInform.pageInfo
      //     : null;
      //   $store.state.fit.pageInform.pageInfo = {};
      //   const pageData = $store.state.fit.pageDepth2.find(data => data.uri === '/bar/pages/product');
      //   $store.state.fit.pageInform.pageInfo.page_id = pageData.id;
      //   $store.state.fit.pageInform.pageInfo.title = pageData.title;
      //   $store.state.fit.pageInform.pageInfo.url = pageData.uri;
      //   $store.state.fit.pageInform.pageInfo.depth = pageData.depth;
      //   $store.state.fit.pageInform.pageInfo.pageAttribute = { click_link: $store.state.fit.clickLink, product_id: Number(to.query.id) };
      //
      //   if ($store.state.common.clickBack) {
      //     $store.state.fit.pageInform.pageInfo.click_back = true;
      //     $store.commit('setClickBack', false);
      //   } else {
      //     $store.state.fit.pageInform.pageInfo.click_back = false;
      //   }
      //
      //   if (!$commons.isNull($store.state.common.alarmId)) {
      //     // APP PUSH 진입
      //     // $store.commit('setSalesPlatform', 'MOBILETP04');
      //     $store.commit('setSalesPlatform', 'BBARS00001');
      //     $store.commit('setMallCode', 'PUSH');
      //     $store.commit('setBannerId', $store.state.common.alarmId);
      //   } else if (Number(to.query.shop_type) === 2) {
      //     $commons.printLog('fitcollabo to.query.shop_type : ', Number(to.query.shop_type));
      //     // 핏콜라보 메뉴 진입
      //     $commons.printLog('pageInform uri : ', $store.state.fit.pageInform.pageInfo.url);
      //     $store.state.fit.pageInform.pageInfo.pageAttribute.type = 1;
      //     // $store.commit('setMallCode', 'MOBILETP02');
      //     if ($store.state.bbarshop.mallCode === '/fit/pages/fitcall') {
      //       $store.commit('setSalesPlatform', 'FIT00FIT01');
      //       $store.state.fit.pageInform.pageInfo.pageAttribute.shop_type = 2;
      //     } else {
      //       $store.commit('setSalesPlatform', 'BBARS00001');
      //       $store.state.fit.pageInform.pageInfo.pageAttribute.shop_type = 1;
      //     }
      //   } else if (!$commons.isNull($store.state.common.sourceId)) {
      //     $store.commit('setSalesPlatform', $store.state.common.sourceId);
      //   } else {
      //     $commons.printLog('bbarshop to.query.shop_type : ', Number(to.query.shop_type));
      //     // 빠숍 메뉴 진입
      //     $store.state.fit.pageInform.pageInfo.pageAttribute.type = 1;
      //     // $store.commit('setSalesPlatform', 'MOBILETP01');
      //     if ($store.state.bbarshop.mallCode === '/fit/pages/fitcall') {
      //       $store.commit('setSalesPlatform', 'FIT00FIT01');
      //       $store.state.fit.pageInform.pageInfo.pageAttribute.shop_type = 2;
      //     } else {
      //       $store.commit('setSalesPlatform', 'BBARS00001');
      //       $store.state.fit.pageInform.pageInfo.pageAttribute.shop_type = 1;
      //     }
      //     // $store.commit('setSalesPlatform', 'BBARS00001');
      //   }
      //   if (!$commons.isNull(pushType)) {
      //     if (pushType === 'EVENT') {
      //       $store.state.fit.pageInform.pageInfo.pageAttribute.click_link = false;
      //     } else {
      //       $store.state.fit.pageInform.pageInfo.pageAttribute.click_link = true;
      //     }
      //   }
      //
      //   if ($commons.isNull(sourceId)) {
      //     await $store.dispatch('informEvent', $store.state.fit.pageInform);
      //   }
      // } else {
      //   // 핏콜 제휴사 페이지 진입
      //   $store.commit('setSalesPlatform', $store.state.common.sourceId);
      //   await $store.dispatch('informEvent', $store.state.fit.pageInform);
      //   // $store.commit('setMallCode', 'MOBILETP03');
      // }
      //
      // $commons.printLog('sourceId', $store.state.common.sourceId);
      // $commons.printLog('bannerId', $store.state.common.bannerId);
      // $commons.printLog('mallCode', $store.state.bbarshop.mallCode);
      // $commons.printLog('salesPlatform', $store.state.common.salesPlatform);
    },
  },
];

export default common;
