<template>
  <div class="popup-wrap">
    <div class="popup-content">서비스 이용에 제한을 받을 수 있습니다.<br />정말 로그아웃 하시겠습니까?</div>
    <div class="popup-button-wrap flex-right-align-wrap">
      <button class="popup-button-no" @click="confirm">아니오</button>
      <button class="popup-button-yes" @click="logout">네</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import bus from '@/utils/bus';

export default {
  name: 'LogoutPopup',
  computed: {
    ...mapGetters(['getDeviceType']),
  },
  methods: {
    async logout() {
      bus.$emit('start:loading');
      this.$store.commit('alertPopup', {});
      if (this.getDeviceType === 1 || this.getDeviceType === 3) await this.unSubscribeFCM();
      const data = this.$store.dispatch('logout');
      data.then(data => {
        if (parseInt(data.resultCode) === 0) {
          this.paiLogout();
          // this.removeUserInfo();
        } else {
          bus.$emit('end:loading');
          this.$utils.errorMessage(this, data);
        }
      });
    },
    async paiLogout() {
      const params = {
        device_id: !this.$commons.isNull(this.$store.state.common.saveDeviceId) ? this.$store.state.common.saveDeviceId : null,
        device_type: this.getDeviceType,
        fit_token: await this.$bridge.stringValue('loginToken'),
      };

      const data = await this.$store.dispatch('paiLogout', params);
      bus.$emit('end:loading');
      await this.removeUserInfo();
    },
    async unSubscribeFCM() {
      const data = await this.$store.dispatch('fetchSubscribeList');

      this.$commons.printLog('☆ fcm subscribe data : ', JSON.stringify(data));
      if (!this.$commons.isNull(data)) {
        for (let topic of data.topics) {
          const payload = {
            topic: `${topic}`,
            token: this.$store.state.common.token,
          };

          this.$commons.printLog('===================================================');
          this.$commons.printLog('☆ fcm fcmUnSubscribe payload : ', JSON.stringify(payload));
          await this.$store.dispatch('fcmUnSubscribe', payload);
        }
        //0110000 는 비회원/로그아웃 상태일때 해당 기기로 보내질 푸쉬 토픽
        const params = {
          topic: '01100000',
          token: this.$store.state.common.token,
        };
        await this.$store.dispatch('fcmSubscribe', params);
      }
    },
    confirm() {
      this.$store.commit('alertPopup', {});
    },
    async removeUserInfo() {
      // 자동 로그인 정보 삭제
      this.$bridge.setStringValue('userPhone', '');
      this.$bridge.setStringValue('userId', '');
      this.$bridge.setStringValue('session', '');
      this.$bridge.setStringValue('reqDate', '');
      this.$bridge.setStringValue('access_token', '');
      this.$bridge.setStringValue('renewal_token', '');
      this.$bridge.setStringValue('loginToken', '');
      this.$bridge.setStringValue('autoLoginYn', 'N');

      // 스토어 정보 날리기
      await this.$store.commit('resetPointState');
      await this.$store.commit('resetRpState');
      await this.$store.commit('resetUserState');
      await this.$store.commit('resetTvpayState');
      await this.$store.commit('resetPaiState');

      // await this.$store.commit("isLogin", false);
      // await this.$store.commit("isAutoCharge", "N");
      // await this.$store.commit("isAutoTrans", "N");
      // 로그인 정보 초기화
      // await this.$store.commit('saveLogOutData');
      await this.$router.push('/initPage');
    },
  },
};
</script>

<style scoped>
.popup-button-yes {
  margin-left: 46px;
  font-size: 30px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #648cff;
  font-weight: bold;
  background-color: #ffffff;
  width: 43px;
}

.popup-button-no {
  font-size: 30px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #444648;
  background-color: #ffffff;
}
.popup-button-wrap {
  margin-top: 40px;
  margin-left: 330px;
}

.popup-content {
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}

.popup-title {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.24;
  letter-spacing: -1.7px;
  text-align: left;
  color: #343534;
}
.popup-wrap {
  padding: 40px;
}
</style>
