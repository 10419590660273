<template>
  <div>
    <div class="popup-wrap">
      <div class="popup-content" v-html="content"></div>
      <div class="popup-button-wrap flex-right-align-wrap">
        <button class="popup-button-no" @click="close">닫기</button>
        <button class="popup-button-yes" @click="revertCancel">취소신청 철회</button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'RevertOrderCancelPopup',
  props: {
    title: {
      type: String,
      default: '알림',
      require: false,
    },
    content: {
      type: String,
      require: true,
      default: '',
    },
  },

  methods: {
    close() {
      this.$store.commit('alertPopup', {});
    },
    revertCancel: _.debounce(
      async function () {
        let authToken = await this.$bridge.stringValue('loginToken');
        const params = {
          tid: this.$store.state.orderPayment.getOrderDetail.pay.tid,
          authToken: authToken,
          appcardSubUrl: '/FIT-ORDER-RP-V1/order/undoOrderCancel.mv',
        };
        await this.$store.dispatch('fitReqRelay', params).then(data => {
          if (data.resultCode === '0000') {
            this.$commons.printLog('주문 취소신청 철회 success');
            this.close();
            this.$store.commit('cancelReq', !this.$store.state.orderPayment.cancelReq);
          } else {
            this.$commons.printLog('주문 취소신청 철회 error');
            this.close();
            this.$store.commit('setCommonToastPopup', { content: data.resultData.contents });
          }
        });
      },
      450,
      false,
    ),
  },
};
</script>

<style lang="scss" scoped>
.popup-wrap {
  padding: 40px;
}

.popup-button-yes {
  margin-left: 46px;
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #648cff;
  font-weight: bold;
  background-color: #ffffff;
}
.popup-button-no {
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #444648;
  background-color: #ffffff;
}
.popup-button-wrap {
  margin-top: 57px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: flex-end;
}

.popup-content {
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.5px;
  text-align: left;
  color: #343534;
}
</style>
